<template>
  <div>

  <section v-if="this.listing" class="container">
    <div class="row">
      <div class="col-lg-5 col-sm-12 col-xs-12">
        <div id="competition-item">
            <h1 class="competition-title">{{this.listing.title}}</h1>
            <img v-bind:src="'data:image/jpeg;base64,'+this.listing.featuredImage" alt=""/>
        </div>
        
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="70" viewBox="0 0 1225 152">
          <defs>
            <filter id="Rectangle_196" x="0" y="0" width="1225" height="152" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_196)">
            <rect id="Rectangle_196-2" data-name="Rectangle 196" width="1207" height="134" rx="67" transform="translate(9 6)" fill="#4fd1c1"/>
          </g>
          <ellipse id="Ellipse_634" data-name="Ellipse 634" cx="55.5" cy="58" rx="55.5" ry="58" transform="translate(18 15)" fill="#fff"/>
          <g id="Icon_ionic-md-time" data-name="Icon ionic-md-time" transform="translate(33.703 33.542)">
            <path id="Path_5" data-name="Path 5" d="M43.564,3.375A40.228,40.228,0,1,0,83.83,43.6,40.213,40.213,0,0,0,43.564,3.375Zm.039,72.41A32.182,32.182,0,1,1,75.785,43.6,32.182,32.182,0,0,1,43.6,75.785Z" transform="translate(-3.375 -3.375)" fill="#4fd1c1"/>
            <path id="Path_6" data-name="Path 6" d="M22.572,10.688H16.537V34.824L37.657,47.492l3.017-4.951-18.1-10.734Z" transform="translate(19.667 9.426)" fill="#4fd1c1"/>
          </g>
          <circle id="Ellipse_635" data-name="Ellipse 635" cx="54" cy="54" r="54" transform="translate(523 23)" fill="#fff"/>
          <text id="_30_100" data-name="30 / 100" transform="translate(766 91)" fill="#fff" font-size="53" font-family="NirmalaUI, Nirmala UI"><tspan x="-96.27" y="0">{{ticketsLeft}} / {{this.listing.maxTickets}}</tspan></text>
          <path id="Icon_awesome-ticket-alt" data-name="Icon awesome-ticket-alt" d="M15.378,16.033H53.821V39.1H15.378ZM63.432,27.566A5.767,5.767,0,0,0,69.2,33.333V44.866a5.767,5.767,0,0,1-5.767,5.767H5.767A5.767,5.767,0,0,1,0,44.866V33.333a5.767,5.767,0,0,0,5.767-5.767A5.767,5.767,0,0,0,0,21.8V10.267A5.767,5.767,0,0,1,5.767,4.5H63.432A5.767,5.767,0,0,1,69.2,10.267V21.8A5.767,5.767,0,0,0,63.432,27.566ZM57.666,15.072a2.883,2.883,0,0,0-2.883-2.883H14.416a2.883,2.883,0,0,0-2.883,2.883V40.061a2.883,2.883,0,0,0,2.883,2.883H54.782a2.883,2.883,0,0,0,2.883-2.883Z" transform="translate(542.127 49.007)" fill="#4fd1c1"/>
          <text id="_3.50" data-name="3.50" transform="translate(1119 92)" fill="#fff" font-size="51" font-family="NirmalaUI, Nirmala UI"><tspan x="-46.767" y="0">{{this.listing.ticketPrice.toFixed(2)}}</tspan></text>
          <text id="_09_19:15:15" data-name="09 19:15:15" transform="translate(307 76)" fill="#fff" font-size="54" font-family="NirmalaUI, Nirmala UI"><tspan x="-135.541" y="0">{{timeLeft}}</tspan></text>
          <text id="DAYS" transform="translate(203 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-31.281" y="0">DAYS</tspan></text>
          <text id="HRS" transform="translate(278 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-24.744" y="0">HRS</tspan></text>
          <text id="MIN" transform="translate(350 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-25.8" y="0">MIN</tspan></text>
          <text id="SEC" transform="translate(421 115)" fill="#fff" font-size="25" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-21.46" y="0">SEC</tspan></text>
          <circle id="Ellipse_637" data-name="Ellipse 637" cx="58" cy="58" r="58" transform="translate(925 15)" fill="#fff"/>
          <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M45.506,40.338V53.407a1.1,1.1,0,0,1-1.14,1.14H10.323a1.1,1.1,0,0,1-1.14-1.14V48.065a1.156,1.156,0,0,1,1.14-1.14h3.454V33.287H10.394a1.079,1.079,0,0,1-.819-.338,1.126,1.126,0,0,1-.321-.8V27.482a1.1,1.1,0,0,1,1.14-1.14h3.383V18.4a12.91,12.91,0,0,1,4.4-10.042,16.12,16.12,0,0,1,11.2-3.953A18.171,18.171,0,0,1,41.3,8.858a1.051,1.051,0,0,1,.356.73,1.1,1.1,0,0,1-.249.8l-3.668,4.522a1.092,1.092,0,0,1-.783.427.954.954,0,0,1-.819-.249,7.824,7.824,0,0,0-.926-.677,12.966,12.966,0,0,0-2.457-1.14,9.408,9.408,0,0,0-3.312-.641,7,7,0,0,0-4.879,1.674,5.625,5.625,0,0,0-1.852,4.38v7.656H33.576a1.126,1.126,0,0,1,.8.321,1.079,1.079,0,0,1,.338.819v4.665a1.156,1.156,0,0,1-1.14,1.14H22.715v13.5H37.458V40.338a1.126,1.126,0,0,1,.321-.8A1.079,1.079,0,0,1,38.6,39.2h5.769a1.079,1.079,0,0,1,.819.338,1.126,1.126,0,0,1,.32.8Z" transform="translate(956.511 44.116)" fill="#4fd1c1"/>
        </svg>
        

      <div class="description mt-4">
        <h3>Product Description</h3>
        <p v-html="this.listing.description"></p>
      </div>
      </div>

      <div class="d-none d-lg-block col-lg-2">
        <div id="competition-indicator">
          <div class="indicator">   
            <div class="end">
              {{this.listing.maxTickets}}
            </div>  
            <div class="start">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                <g id="Ellipse_293" data-name="Ellipse 293" fill="#4fd1c1" stroke="#4fd1c1" stroke-width="1">
                  <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                  <circle cx="20.5" cy="20.5" r="20" fill="none"/>
                </g>
                <text id="_1" data-name="1" transform="translate(12 31)" fill="#fff" font-size="28" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="0" y="0">1</tspan></text>
              </svg>  -->
              1
            </div> 
            <div ref="soldCounter" id="tracker" class="tracker">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 80 80">
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="40" cy="40" r="40" fill="#00bca4"/>
                <path id="Icon_awesome-ticket-alt" data-name="Icon awesome-ticket-alt" d="M11.832,13.374H41.41V31.121H11.832Zm36.974,8.874a4.437,4.437,0,0,0,4.437,4.437v8.874a4.437,4.437,0,0,1-4.437,4.437H4.437A4.437,4.437,0,0,1,0,35.558V26.684a4.437,4.437,0,0,0,4.437-4.437A4.437,4.437,0,0,0,0,17.81V8.937A4.437,4.437,0,0,1,4.437,4.5H48.805a4.437,4.437,0,0,1,4.437,4.437V17.81A4.437,4.437,0,0,0,48.805,22.247Zm-4.437-9.613a2.218,2.218,0,0,0-2.218-2.218H11.092a2.218,2.218,0,0,0-2.218,2.218V31.86a2.218,2.218,0,0,0,2.218,2.218H42.15a2.218,2.218,0,0,0,2.218-2.218Z" transform="translate(14 17.5)" fill="#fff"/>
              </svg>
            </div>         
          </div>

        </div>
      </div>


      
      <div class="col-lg-5 col-sm-12 col-xs-12 text-center">
        <div class="row luckydip mt-xs-5 mt-md-2 mx-auto">
          <div class="d-none col-4 mt-2">
            Select tickets
          </div>
          <div class="col">
          </div>
        </div>

        <button type="button" class="btn btn-custom" v-on:click="luckyDip">Lucky Dip</button>

        <div id="tickets-table">

            <div id="tickets">
              <TicketButton v-bind:selectToggled="selectToggled" v-bind:entry="entry" :ref="setItemRef" v-for="entry in filteredEntries" v-bind:selectedEntries="selectedEntries" v-bind:key="'ticket-'+entry.number"/>
            </div>

            <div className="d-flex justify-content-center pt-3">
              <nav v-if="this.listing.maxTickets > 100" aria-label="Page navigation text-center">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" v-on:click="prevPage()" href="#">Previous</a></li>
                  <li class="page-item" v-for="page in totalPages" v-bind:key="'page-'+page" v-on:click="currentPage(page)"><a class="page-link" href="#">{{page}}</a></li>
                  <li class="page-item"><a class="page-link" v-on:click="nextPage()" href="#">Next</a></li>
                </ul>
              </nav>
            </div>

            <div class="row" style="margin-top:15px;">
              <div class="col-6" style="text-align: left;">
                <span>Max {{this.listing.personMaxTickets}} tickets per player</span>
              </div>
              <div class="col-6" style="text-align: right;">
                <router-link v-bind:to="'/terms-and-conditions#freeentry'">Free entry</router-link>
              </div>
            </div>
          
          <!-- Generate table with for loop and pass number to select number. -->

        </div>


        <form id="payment_method" class="mt-2">
          <div class="total mt-3">

            <label class="text-start mt-2 mb-2 total_title">Total</label>

            <div class="total_price p-1">
              <div class="row">
                <div class="col-6">
                  <div class="amount text-start pt-1" style="padding-left:10px">{{this.selectedEntries.length}}x tickets</div>
                </div>
                <div class="col-6">
                  <div class="text-end">
                    <svg xmlns="http://www.w3.org/2000/svg" width="143" height="33" viewBox="0 0 105 44">
                      <circle id="Ellipse_225" data-name="Ellipse 225" cx="22" cy="22" r="22" fill="#00bca4"/>
                      <rect id="Rectangle_169" data-name="Rectangle 169" width="143" height="44" rx="22" fill="rgba(0,188,164,0.27)"/>
                      <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#fff"/>
                      <text id="_10.50" data-name="10.50" transform="translate(90 33)" fill="#fff" font-size="30" font-family="NirmalaUI, Nirmala UI"><tspan x="-35.596" y="0">{{(this.selectedEntries.length*this.listing.ticketPrice).toFixed(2)}}</tspan></text>
                    </svg>                          
                  </div>
                </div>
              </div>
            </div>


          </div>

          <label class="mb-2 small text-start mt-2">{{this.listing.question.question}}</label>
          <select v-model="selectedAnswer" name="method_type" id="method_type">
            <option v-for="answer in this.listing.question.answers" v-bind:key="answer" v-bind:value="answer">{{answer}}</option>
          </select>
            <div class="row mt-3">
              <div class="col">
                <button v-on:click="purchaseTickets" type="button" class="btn btn-custom mt-3">Pay</button> 
              </div>
            </div>
        </form>


      </div>           
    </div>
  </section>


  <Footer/>
  </div>
</template>

<script>
import Api from '../scripts/api'

import Footer from '../components/global/Footer'

import TicketButton from '../components/competition/TicketButton';

export default {
    components: {
        Footer,
        TicketButton
    },
    props: {
      account: Object,
    },
    data() {
      return {
        now: Date.now(),
        listing: undefined,
        // termsOfService: false,
        selectedAnswer: undefined,
        selectedEntries: [],
        allEntries: [],
        perPage: 100,
        start: 0,
        page: 1,
        end: 999999,
      }
    },
    computed: {
        totalPages: function () {return Math.ceil(this.listing.maxTickets / this.perPage)},
        // totalPages: this.listing.entries.length,
        // totalPages: Math.ceil(this.listing.entries.length / this.perPage),
        filteredEntries: function () {
          return this.listing.entries.slice(this.start, this.end)
        },
        ticketsLeft: function() {
            var left = this.listing.maxTickets;
            for(var entry in this.listing.entries) {
                if(this.listing.entries[entry].bought) {
                    left--;
                }
            }
            return left;
        },
        timeLeft: function() {
          if(this.listing.hasEnded) return 'Ended';

          this.now.toString(); // force update
          var endDate = this.listing.endDate;
          function getTimeRemaining(endtime) {
            const total = Date.parse(endtime) - Date.parse(new Date());
            const seconds = Math.floor((total / 1000) % 60);
            const minutes = Math.floor((total / 1000 / 60) % 60);
            const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
            const days = Math.floor(total / (1000 * 60 * 60 * 24));
            
            return {
              total,
              days,
              hours,
              minutes,
              seconds
            };
          }

          function addZeroIfSingleNumber(number) {
            var num = number.toString();
            if(num.length <= 1) return '0'+num;
            return num;
          }

          var timeRemaining = getTimeRemaining(endDate);
          return addZeroIfSingleNumber(timeRemaining.days) + ' ' + addZeroIfSingleNumber(timeRemaining.hours) + ':' + addZeroIfSingleNumber(timeRemaining.minutes) + ':' + addZeroIfSingleNumber(timeRemaining.seconds); //'09 19:15:15'
        }
    },
    methods: {
      prevPage() {
        let page = this.page;

        page = this.page - 1;
        this.currentPage(page);
      },
      currentPage(page) {
        this.page = page;

        if(this.page < 1) {
          page = 1;
        } 
        if(this.page > this.totalPages) {
          page = this.totalPages;
        }

        this.start = (page - 1) * this.perPage;
        this.end = page * this.perPage;
      },
      nextPage() {
        let page = this.page;

        while(page < this.totalPages) {
          page = this.page + 1;
          break;
        }
        this.currentPage(page);
      },
      setItemRef: function(el) {
        this.allEntries.push(el);
      },
      selectToggled: function(entry) {  
        if (!this.account|| !this.account.account) this.$router.push('/login');
        if (this.account && !this.account.account.email.confirmed) {
          entry.selected = false;
          return this.$toast.warning('You must confirm your email before you join the competition.'); 
        }

        if(entry.selected) { 
          if(this.listing.personMaxTickets == 0) {
            entry.selected = false;
            return this.$toast.error('Already entered competition!');
          }
          // Check if maxed out on selects.
          if(this.selectedEntries.length >= this.listing.personMaxTickets) {
            entry.selected = false;
            return this.$toast.warning('Selected max amounts of tickets per person already!');
          }

          this.selectedEntries.push(entry.number); 
        }
        else this.selectedEntries = this.selectedEntries.filter(e => e !== entry.number);
      },
      luckyDip: function() {
        if (!this.account|| !this.account.account) this.$router.push('/login');
        if (this.account && !this.account.account.email.confirmed) return this.$toast.warning('You must confirm your email before you join the competition.'); 

        var items = this.allEntries.filter(e=> e && !e.selected && !e.entry.bought);
        if(items.length > 0) {
          var item = items[Math.floor(Math.random() * items.length)];
          item.toggleSelection();

          var page = Math.ceil(item.number / this.perPage);
          this.currentPage(page);
        }
      },
      purchaseTickets: async function() {
        if (!this.account || !this.account.account) this.$router.push('/login');

        if (this.account && !this.account.account.email.confirmed) return this.$toast.warning('You must confirm your email before you join the competition.'); 

        // if(!this.termsOfService) return this.$toast.warning('Please accept the terms & conditions');
        if(this.selectedEntries.length < this.listing.personMinTickets) return this.$toast.warning(`Please select at least ${this.listing.personMinTickets} tickets.`);
        if(!this.selectedAnswer)return this.$toast.warning(`Please answer the skill based question.`);

        var result = await Api.competition.buyTickets(this.$route.params.id, this.selectedEntries, this.selectedAnswer);
        
        if(result.status == 403) return this.$toast.error('You are not eligible for this competition!');
        if(result.status == 404) return this.$toast.error('Invalid competition selected, try again later.');
        if(result.status == 410) return this.$toast.error('The competition has already ended.');
        if(result.status == 409) return this.$toast.error(`One or more of your selected tickets have already been purchased. Please unselect ticket(s) with number(s) ${(await result.json()).tickets}.`)
        // if(result.status == 412) return this.$toast.error('You don\'t have enough balance to purchase ticket(s), please top up.');
        if(result.status == 412) window.location.href="/dashboard/topup"
        if(result.status == 417) return this.$toast.error('You tried buying too many tickets. Please refresh the page.');

        if(result.status == 200) {
          window.location.replace(`/competition/${this.$route.params.id}?action=purchase-success`);
        }
      }
    },
    async created() {
      var self = this
      setInterval(function() {
        self.now = Date.now();
      }, 1000);

      var result = await Api.competition.getById(this.$route.params.id);
      if(result.status == 200) {
        this.listing = await result.json();
      } else {
        this.$toast.warning('Error occured');
        setTimeout(function() {
          this.$router.push('/');
        }, 1000);
      }

      if(this.$route.query.action == 'purchase-success') this.$toast.success('Successfully purchased tickets!');
    },
    updated() {
      if(this.listing) {
        const maxToAdd = 630;
        const startPos = -549;
        var toSet = startPos + (maxToAdd*(1-this.ticketsLeft/this.listing.maxTickets));
        this.$refs.soldCounter.style.bottom = toSet + 'px';
      }
      /* Fix to render all items for lucky dip, then reset max. */
      if(this.end == 999999)
        this.end = this.perPage;
    }
}
</script>
