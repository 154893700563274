<template>
    <button v-on:click="toggleSelection" class="ticket-btn" data-toggle="tooltip" data-placement="top" v-bind:style="{ 'background-color': this.getColor() }" v-bind:title="entry.bought ? entry.userName : 'Available'">{{entry.number}}</button>
</template>

<script>
export default {
    props: {
        entry: Object,
        selectToggled:Function,
        selectedEntries:Array
    },
    data() {
        return {
            selected: false,
            number: 0
        }
    },
    methods: {
        getColor: function() {
            if(this.entry.bought) return '#757575';
            if(this.selected) return '#4FD1C1';
            return 'white';
        },
        toggleSelection: function() {
            if(this.entry.bought) return;
            this.selected = !this.selected;
            this.selectToggled(this);            
        }
    },
    created() {
        this.number = this.entry.number;
        if(this.selectedEntries && this.selectedEntries.includes(this.number)) {
            this.selected = true;
        }
    }
}
</script>